import React from 'react'
import MealsSummary from './MealsSummary'
import AvailableMeals from './AvailableMeals'

function Meals() {
  return (
    <div>
        <MealsSummary /> 
        <AvailableMeals />
    </div>
  )
}

export default Meals